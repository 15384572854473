import React, { Component } from 'react';
import './App.css';
import logo from './images/logo.png';


class App extends Component {

  render() {
    return (
      <div>
        <div className="center-relative">
          <h1 className="entry-title">

            Handpicked <b>technology information</b> <br />
            crafted with much <span className="heart">❤</span> and care for every detail.
          </h1>
        </div>


        <div className="grid-container">
          <div className="Imagem-2" onClick={() => window.location.assign("https://radar.solutis.digital/data-intelligence/")}>
            <div className="transclass transclass:hover small-card"> <section><div>DATA INTELLIGENCE</div><span>IA & Analytics Tech </span></section></div>
          </div>
          <div className="Imagem-3" onClick={() => window.location.assign("https://radar.solutis.digital/dev-backend/")}>
            <div className="transclass transclass:hover small-card"><section><div>DEV BACKEND</div><span>Server Side Tech</span></section></div>
          </div>
          <div className="Imagem-4" onClick={() => window.location.assign("https://radar.solutis.digital/studio-concepts/")}>
            <div className="transclass transclass:hover big-card"> <section><div>STUDIO CONCEPTS</div><span>Culture and Mindset</span></section></div>
          </div>
          <div className="Imagem-1" onClick={() => window.location.assign("https://radar.solutis.digital/dev-frontend/")}>
            <div className="transclass transclass:hover big-card"> <section><div> DEV FRONTEND</div><span>Client Side Tech</span></section></div>
          </div>
          <div className="Imagem-Outra" onClick={() => window.location.assign("https://radar.solutis.digital/ops-infrastructure/")}>
            <div className="transclass transclass:hover small-card"><section><div>IT OPS</div><span>Operations Tech</span></section></div>
          </div>
        </div>

        <div className="header-logo center-text">
          <a href="https://solutis.com.br">
            <img src={logo} alt="Solutis" height="50" />
          </a>
        </div>
      </div >

    );
  }
}

export default App;

